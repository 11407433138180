.connect-label {
  color: #0F172A;
  font-size: 11px;
  font-weight: 700px;
  line-height: 160%;
}

.connect-required {
  color: #ED4F9D;
}

.connect-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  padding: 40px;
}

.connect-btn {
  width: 100%;
  height: 47px;
  border-radius: 6.7px;
  border: 1px solid #F5F5F5;
  color: #D97706;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
}

.connect-btn:disabled {
  opacity: 0.7;
}

.save-btn {
  background-color: #D97706;
  color: #fff;
}

.btn-close {
  background-color: #F8FAFC !important;
  border-radius: 6.7px !important;
  padding: 7px !important;
}

.modal-header {
  border-bottom: 1px solid #F1F5F9 !important;
}