.signature {
  width: 200px;
  object-fit: contain;
}


.profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.profile img {
  position: absolute;
  top: 50px;
  left: 50px;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.text-green {
  color: #059669 !important;
}

.text-red {
  color: #DC1515 !important;
}

.text-orange {
  color: #D97706;
}

.img-wrapper {
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}

.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.profile-img img {
  position: absolute;
  top: 25px;
  left: 25px;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

/* a:hover {
  color: #ffffff !important;
} */