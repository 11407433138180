//
// _table.scss
//

.table {
  th {
    font-weight: 700;
    color: #90a4ba;
    font-family: "Mulish", sans-serif !important;
    font-size: 14px !important;
  }
}

tr {
  height: 65px !important;
  cursor: pointer;
}
thead {
  background-color: #f8fafc;
  border-radius: 12px;
  height: 65px;
  white-space: nowrap;
  border: none;
}

thead tr th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
thead tr th:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

tr td {
  font-size: 14px !important;
  color: #0D172B;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
  background-color: white !important;
}

tr td:last-child {
  font-size: 11px !important;
  font-weight: 700;
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}
