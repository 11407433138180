.profile-head {
  color: #2c2c2c;
  font-size: 24px;
  font-weight: 600;
}
.profile-card {
  border-radius: 10px !important;
  border: 1px solid #f2f2f2 !important;
  min-height: 170px;
}

.profile-wallet {
  border-radius: 10px !important;
  border: 1px solid #059669 !important;
  background-color: #ebfff8 !important;
  min-height: 170px;
}
.profile-name {
  font-size: 24px;
  font-weight: 700;
  color: #4b4b4b;
}

.details {
  font-size: 16px;
  font-weight: 500;
  color: #989898;
}

.change,
.send {
  background-color: #d97706;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
}

.edit {
  background-color: #1111110D;
  color: #111111;
  padding: 4px 8px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.change:disabled,
.send:disabled {
  filter: brightness(0.75);
}

.send {
  background-color: #059669;
}

.send:hover {
  filter: brightness(0.9);
}

.wallet {
  font-size: 16px;
  font-weight: 600;
  color: #059669;
}

.balance {
  font-size: 40px;
  font-weight: 700;
  color: #059669;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #F88600 !important;
  border-radius: 42px;
}

.nav-pills .nav-link {
  color: #d97706 !important;
}

.payment {
  min-width: 350px;
  height: 100%;
  background-color: #ebfff8;
  border: 1px solid #059669;
  padding: 20px;
  border-radius: 7px;
}

.number {
  text-align: center;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 700;
  margin-top: 15px;
}

.sub-details {
  font-size: 12px;
  font-weight: 400;
  color: #c5c7cd;
}

.profile-img {
  width: 250px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.sign-img {
  width: 100px;
  max-height: 50px;
  object-fit: cover;
}

.modal-body {
  background-color: #f7f7f7 !important;
}

.price-head {
  font-weight: 700;
  color: #9fa2b4;
  font-family: "Mulish", sans-serif !important;
  font-size: 14px !important;
}

.driver-price {
  font-size: 18px;
  font-weight: 600;
  color: #d97706;
}

.package-img {
  width: 250px;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .payment {
    min-width: 300px;
  }
}

@media screen and (max-width: 376px) {
  .payment {
    min-width: 280px;
  }
}

.list-group {
  display: block;
  max-height: 200px;
  overflow-y: scroll;
}

/* .list-group-item {
  background-color: red !important;
} */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form-select {
  padding: 12px !important;
  border-radius: 8px !important;
}

.action-item {
  transform: translateX(-130px) !important;
}

small {
  color: #989898;
  font-weight: 600;
}

.cont {
  color: #989898;
}
