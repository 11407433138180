.file {
  border: 1px solid #f3f4f7;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
}

.file-svg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-svg {
  border: 1px solid #f8fafb;
  background-color: #f2f4f7;
  padding: 8px;
  border-radius: 50%;
}

.drop-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 12px;
  color: #647187;
}
.upload-text {
  color: #e87000;
}

.type-text {
    font-size: 14px;
    font-weight: 400;
    color: #647187;
    line-height: 18px;
}

.main-content {
    border: 1px solid #FFF2BC;
    padding: 20px;
    border-radius: 8px;
    margin: 8px 0;
}
