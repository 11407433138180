.order-detials {
  color: #0e1829;
  margin-top: 24px;
  margin-left: 16px;
  margin-bottom: 19px;
  font-size: 18px;
}

.details-row {
  background-color: #fafafa;
  margin: 9px 16px;
  padding: 10px;
  border-radius: 8px;
}

.details-name {
  color: #9aa3b0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  margin-bottom: 6px;
}

.details-info {
  color: #e87000;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.details-content {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.details-variant {
  margin: 9px 16px;
  padding: 10px;
  border-radius: 8px;
}

.package-detials {
  color: #0e1829;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.3px;
  margin: 24px 16px 20px 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f3f4f6;
}

.package-img {
  height: 218px;
  background-size: cover;
  background-position: center center;
  margin-bottom: 12px;
  background-repeat: no-repeat;
}

.order-tracking {
  padding: 22px 24px 31px 24px;
}
.tracking-border {
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  padding: 14px 12px;
}
.main-tracking {
  display: flex;
  gap: 14px;
}

.tracking-img {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.tracking-line {
  border-left: 1px dashed #f9f9f9;
  height: 56px;
}
